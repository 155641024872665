import React, { useEffect, useState } from 'react';
import { streamAuth } from '../../services/firebase';

type Props = {
    children: any;
};

const Auth = ({ children }: Props) => {
    const [authenticated, setAuthenticated] = useState<boolean | undefined>(undefined);

    useEffect(() => {
        const authSubscribe = streamAuth((user) => {
            setAuthenticated(!!user);
        });

        return authSubscribe;
    }, []);

    if (authenticated === undefined) {
        return <div>Carregando...</div>;
    }

    return children(authenticated);
};

export default Auth;
