import React, { useState, useEffect, useMemo } from 'react';
import { streamListSchedule } from '../../services/firebase';
import ListScheduleds from './TableScheduleds';
import { Tab, Tabs, Typography } from '@mui/material';
import { getBaseHours } from '../../utils/utils';
import { Schedule, Item, Service, NewSchedule, NewScheduleCalendar, ScheduleItem, AlertFeedbackData, Config } from '../../types';
import ModalAddSchedule from './ModalAddSchedule';
import ModalScheduleDetails from './ModalScheduleDetails';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { AlertFeedback } from '../../Components';
import { styled } from '@mui/system';

const DivInputDay = styled('div')({
  minWidth: '150px',
});
const DivDays = styled('div')({
  '@media(max-width: 640px)': {
    display: 'none',
  }
});

type Props = {
  config: Config;
  employees: Item[];
  services: Service[];
  baseDays: Item[];
}

export default function ScheduleComponent({
  config,
  employees,
  services,
  baseDays,
}: Props) {
  const [day, setDay] = useState<string>(baseDays[0]?.id || '');

  const baseHours = useMemo(() => getBaseHours(config?.minutesEachScheduled || 0, config?.daysWork || [], day), [config, day]);
  const dayMoment = useMemo<moment.Moment>(() => moment(day), [day]);

  const [newSchedule, setNewSchedule] = useState<NewSchedule | undefined>(undefined);
  const [detailsSchedule, setDetailsSchedule] = useState<ScheduleItem | undefined>(undefined);

  const [scheduleData, setScheduleData] = useState<ScheduleItem[]>([]);
  const [error, setError] = useState<string>();
  const [feedback, setFeedback] = useState<AlertFeedbackData>();


  useEffect(() => {
    const unsubscribe = streamListSchedule(
      day,
      (querySnapshot: any) => {
        const updatedGroceryItems =
          querySnapshot.docs.map((docSnapshot: any) => docSnapshot.data());
        setScheduleData(updatedGroceryItems);
      },
      (error: any) => {
        console.log('error', error)
        setError('Erro buscando dados, favor recarregar a página.');
      }
    );
    return unsubscribe;
  }, [setScheduleData, day]);

  const schedules = useMemo<Schedule[]>(() => {
    return baseHours.map((hour): Schedule => ({
      hour,
      employees: employees.map(employee => scheduleData.find(schedule => schedule.hour === hour && schedule.employee === employee.id)),
    }));
  }, [scheduleData, employees, baseHours]);

  const handleChangeDayTab = (event: React.SyntheticEvent, newValue: string) => {
    setDay(newValue);
  };

  const handleChangeDayInput = (newValue: moment.Moment | null) => {
    if (newValue) {
      setDay(newValue.format('YYYY-MM-DD'));
    }
  };

  const onShowAddSchedule = (newScheduleParams: NewScheduleCalendar) => {
    setNewSchedule({
      service: services[0].id,
      day,
      ...newScheduleParams,
    });
  }
  const onHideAddSchedule = () => setNewSchedule(undefined);

  const onShowScheduleDetails = (scheduleItem: ScheduleItem) => {
    const employee = employees.find(employee => employee.id === scheduleItem.employee);

    setDetailsSchedule({
      ...scheduleItem,
      employee: employee?.name || scheduleItem.employee,
    });
  };

  const onHideScheduleDetails = () => setDetailsSchedule(undefined);

  return (
    <>
      {error && <Typography variant='h5'>{error}</Typography>}
      <br />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <DivInputDay>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker value={dayMoment} onChange={handleChangeDayInput} />
          </LocalizationProvider>
        </DivInputDay>
        <DivDays>
          <Tabs value={day} onChange={handleChangeDayTab} aria-label="tabs Days" scrollButtons="auto" variant="scrollable">
            {
              baseDays.map(day => (
                <Tab key={day.id} label={day.name} value={day.id} />
              ))
            }
          </Tabs>
        </DivDays>
      </div>
      <ListScheduleds schedules={schedules} employees={employees} onShowAddSchedule={onShowAddSchedule} onShowScheduleDetails={onShowScheduleDetails} />
      {
        !!newSchedule && (
          <ModalAddSchedule
            minutesEachScheduled={config?.minutesEachScheduled || 0}
            service={newSchedule.service}
            listServices={services}
            employeeId={newSchedule.employee}
            listEmployees={employees}
            day={newSchedule.day}
            listDays={baseDays}
            hour={newSchedule.hour}
            listHours={baseHours}
            onClose={onHideAddSchedule}
            setFeedback={setFeedback}
          />
        )
      }
      {
        !!detailsSchedule && (
          <ModalScheduleDetails
            schedule={detailsSchedule}
            services={services}
            onClose={onHideScheduleDetails}
            setFeedback={setFeedback}
          />
        )
      }
      <AlertFeedback alert={feedback} setClose={setFeedback} />
    </>
  );
};
