import { initializeApp } from 'firebase/app';
import {
    getFirestore,
    collection,
    doc,
    setDoc,
    addDoc,
    deleteDoc,
    getDocs,
    getDoc,
    connectFirestoreEmulator,
    onSnapshot,
    query,
    orderBy,
    where,
    DocumentData,
} from 'firebase/firestore';
import {
    getFunctions,
    httpsCallable,
    connectFunctionsEmulator
} from 'firebase/functions';
import {
    getAuth,
    RecaptchaVerifier,
    signInWithEmailAndPassword,
    signOut,
    browserLocalPersistence,
    connectAuthEmulator,
    onAuthStateChanged,
    signInWithPhoneNumber,
} from 'firebase/auth';
import { getMessaging } from "firebase/messaging";
import { Client, Item, NewSchedule, Service, ScheduleItem, Context, NewEmployee, NewService, Config } from '../types';

const firebaseConfig = {
    apiKey: "AIzaSyCyowxO5DoCQxJOwhdl68vshPsZlo-Nvwc",
    authDomain: "agenda-zap-7a65d.firebaseapp.com",
    projectId: "agenda-zap-7a65d",
    storageBucket: "agenda-zap-7a65d.appspot.com",
    messagingSenderId: "432009633431",
    appId: "1:432009633431:web:fc89eef29296fb27b053d2",
    measurementId: "G-JZPR4580N3",
};

const app = initializeApp(firebaseConfig);

const db = getFirestore(app);
const functions = getFunctions(app);
const auth = getAuth();
export const messaging = getMessaging(app);

if (process.env.NODE_ENV === 'development') {
    connectFirestoreEmulator(db, '127.0.0.1', 8080);
    connectFunctionsEmulator(functions, '127.0.0.1', 5001);
    connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
}

const TABLE_USERS = 'users';
const TABLE_CONTACTS = 'contacts';
const TABLE_SERVICES = 'services';
const TABLE_CONFIG = 'config';
const TABLE_SCHEDULE = 'schedule';
const TABLE_CONTEXTS = 'contexts';
const TABLE_CLIENTS = 'clients';

export const addSchedule = async (scheduleData: NewSchedule) => httpsCallable(functions, 'schedule-schedule')(scheduleData);

export const cancelSchedule = async (contextId: string) => httpsCallable(functions, 'schedule-cancel')({ context: contextId });

export const listEmployees = async (schedule: boolean = false): Promise<Item[]> => {
    const ref = collection(db, TABLE_USERS);

    let itemsQuery;
    if (schedule) {
        itemsQuery = query(ref, where('active', '==', true), orderBy('order'));
    } else {
        itemsQuery = query(ref, orderBy('order'));
    }

    const snapshot = await getDocs(itemsQuery);

    if (snapshot.empty) return [];

    const data = snapshot.docs.map(doc => ({
        id: doc.id,
        name: doc.data()?.name
    }));

    return data.sort((a, b) => a.name.localeCompare(b.name));
}

export const listServices = async (schedule: boolean = false): Promise<Service[]> => {
    const ref = collection(db, TABLE_SERVICES);

    let itemsQuery;
    if (schedule) {
        itemsQuery = query(ref, where('active', '==', true), orderBy('order'));
    } else {
        itemsQuery = query(ref, orderBy('order'));
    }

    const snapshot = await getDocs(itemsQuery);

    if (snapshot.empty) return [];

    return snapshot.docs.map(doc => {
        const service = doc.data();
        return {
            id: doc.id,
            name: service.name,
            duration: service.duration,
            active: service.active,
            order: service.order,
        }
    });
}

export const listSchedule = async () => {
    const scheduleRef = collection(db, TABLE_SCHEDULE);
    const snapshot = await getDocs(scheduleRef);

    if (snapshot.empty) return [];

    const data: ScheduleItem[] = snapshot.docs.map(doc => {
        const schedule = doc.data();
        return {
            id: doc.id,
            context: schedule.context,
            day: schedule.day,
            employee: schedule.employee,
            hour: schedule.hour,
        }
    });

    return data;
}

export const getContext = async (id: string): Promise<Context | undefined> => {
    const contextRef = doc(db, TABLE_CONTEXTS, id);
    const contextSnapshot = await getDoc(contextRef);

    if (!contextSnapshot.exists()) {
        return undefined;
    }

    const contextData = contextSnapshot.data();

    return {
        service: contextData.service,
        user: contextData.user
    };
}

export const getClient = async (id: string): Promise<Client | undefined> => {
    const clientRef = doc(db, TABLE_CLIENTS, id);
    const clientSnapshot = await getDoc(clientRef);

    if (!clientSnapshot.exists()) {
        return undefined;
    }

    const clientData = clientSnapshot.data();

    return {
        name: clientData.name,
        phone: clientData.phone
    };
}

export const getConfig = async (): Promise<Config | undefined> => {
    const ref = doc(db, TABLE_CONFIG, 'schedule');
    const snapshot = await getDoc(ref);

    if (!snapshot.exists()) {
        return undefined;
    }

    const data = snapshot.data();

    return {
        minutesEachScheduled: data.minutesEachScheduled,
        daysToShow: data.daysToShow,
        daysWork: data.daysWork,
        chatbotEnabled: data.chatbotEnabled,
        rememberEnabled: data.rememberEnabled
    };
}

const streamList = (table: string, snapshot: any, error: any) => {
    const itemsColRef = collection(db, table)
    const itemsQuery = query(itemsColRef)
    return onSnapshot(itemsQuery, snapshot, error);
};

export const streamListEmployee = (snapshot: any, error: any) => streamList(TABLE_USERS, snapshot, error);

export const streamListContacts = (snapshot: any, error: any) => streamList(TABLE_CONTACTS, snapshot, error);

export const streamListService = (snapshot: any, error: any) => streamList(TABLE_SERVICES, snapshot, error);

export const streamListSchedule = (day: string, snapshot: any, error: any) => {
    const itemsColRef = collection(db, TABLE_SCHEDULE)
    const itemsQuery = query(itemsColRef, where('day', '==', day), orderBy('hour'))
    return onSnapshot(itemsQuery, snapshot, error);
};

export const streamWhats = (snapshot: any, error: any) => {
    const WhatsRef = collection(db, 'whats')
    return onSnapshot(WhatsRef, snapshot, error);
};

const createUpdate = async (
    table: string,
    id: string | undefined,
    item: DocumentData,
) => {
    if (id) {
        return setDoc(doc(db, table, id), item);
    } else {
        return addDoc(collection(db, table), item);
    }
};

const deleteItem = async (
    table: string,
    id: string,
) => {
    return deleteDoc(doc(db, table, id));
};

export const createUpdateConfig = async (config: Config) => setDoc(doc(db, TABLE_CONFIG, 'schedule'), config);

export const createUpdateEmployee = async (employee: NewEmployee, id?: string) => createUpdate(TABLE_USERS, employee.id, employee);

export const createUpdateService = async (service: NewService, id?: string) => createUpdate(TABLE_SERVICES, service.id, service);

export const createContact = async (phone: string) => createUpdate(TABLE_CONTACTS, phone, { phone });

export const deleteContact = async (phone: string) => deleteItem(TABLE_CONTACTS, phone);

export const login = async (email: string, password: string) => {
    await auth.setPersistence(browserLocalPersistence);
    await signInWithEmailAndPassword(auth, email, password);
}

export const loginPhone = async (phoneNumber: string) => {
    console.log('phoneNumber', phoneNumber)
    const recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
        callback: () => {
            // reCAPTCHA response received
        },
    });


    await auth.setPersistence(browserLocalPersistence);
    return signInWithPhoneNumber(auth, phoneNumber, recaptchaVerifier);
    // await signInWithEmailAndPassword(auth, email, password);
}

export const logout = async () => signOut(auth);

export const streamAuth = (callback: (user: any) => void) => onAuthStateChanged(auth, callback);
