import React, { useState, useEffect, useMemo } from 'react';
import { createUpdateConfig, getConfig } from '../../services/firebase';
import { Box, Button, Checkbox, Container, FormControlLabel, FormGroup, Paper, TextField, Typography } from '@mui/material';
import { AlertFeedbackData, DayWork } from '../../types';
import DayConfig from './DayConfig';
import { AlertFeedback } from '../../Components';

export type dayWorkEditing = {
  enabled: boolean,
  initialHour?: number,
  initialMinute?: number,
  finalHour?: number;
  finalMinute?: number;
};

const ConfigComponent: React.FC = () => {
  const [ready, setReady] = useState<boolean>(false);
  const [feedback, setFeedback] = useState<AlertFeedbackData>();

  const [chatbotEnable, setChatbotEnable] = useState<boolean>(false);
  const [enableRemember, setEnableRemember] = useState<boolean>(false);

  const [minutesEachScheduled, setMinutesEachScheduled] = useState<number>();
  const [daysToShow, setDaysToShow] = useState<number>();
  const [daysWork0, setDaysWork0] = useState<dayWorkEditing>();
  const [daysWork1, setDaysWork1] = useState<dayWorkEditing>();
  const [daysWork2, setDaysWork2] = useState<dayWorkEditing>();
  const [daysWork3, setDaysWork3] = useState<dayWorkEditing>();
  const [daysWork4, setDaysWork4] = useState<dayWorkEditing>();
  const [daysWork5, setDaysWork5] = useState<dayWorkEditing>();
  const [daysWork6, setDaysWork6] = useState<dayWorkEditing>();

  useEffect(() => {
    const _getConfig = async () => {
      const config = await getConfig();

      if (config) {
        const setDay = (day: string, setDay: React.Dispatch<React.SetStateAction<dayWorkEditing | undefined>>) => {
          const dayConfig = config.daysWork.find(item => item.day === day);
          setDay({
            enabled: !!dayConfig,
            initialHour: dayConfig?.initialHour !== undefined ? dayConfig.initialHour : undefined,
            initialMinute: dayConfig?.initialMinute !== undefined ? dayConfig.initialMinute : undefined,
            finalHour: dayConfig?.finalHour !== undefined ? dayConfig.finalHour : undefined,
            finalMinute: dayConfig?.finalMinute !== undefined ? dayConfig.finalMinute : undefined,
          });
        }

        setChatbotEnable(config.chatbotEnabled);
        setEnableRemember(config.rememberEnabled);
        setMinutesEachScheduled(config.minutesEachScheduled);
        setDaysToShow(config.daysToShow);
        setDay('0', setDaysWork0);
        setDay('1', setDaysWork1);
        setDay('2', setDaysWork2);
        setDay('3', setDaysWork3);
        setDay('4', setDaysWork4);
        setDay('5', setDaysWork5);
        setDay('6', setDaysWork6);
      } else {
        setDaysWork0({ enabled: false });
        setDaysWork1({ enabled: false });
        setDaysWork2({ enabled: false });
        setDaysWork3({ enabled: false });
        setDaysWork4({ enabled: false });
        setDaysWork5({ enabled: false });
        setDaysWork6({ enabled: false });
      }
      setReady(true);
    }
    _getConfig();
  }, []);

  const onChangeMinutes = (event: React.ChangeEvent<HTMLInputElement>) => setMinutesEachScheduled(event.target.value ? Number(event.target.value) : undefined);
  const onChangeDaysToShow = (event: React.ChangeEvent<HTMLInputElement>) => setDaysToShow(event.target.value ? Number(event.target.value) : undefined);
  const onChangeDay = (setDay: React.Dispatch<React.SetStateAction<dayWorkEditing | undefined>>) => (newDay: dayWorkEditing) => setDay(newDay);
  const onChangeEnabledChatbot = (event: React.ChangeEvent<HTMLInputElement>) => setChatbotEnable(event.target.checked);
  const onChangeEnabledRemember = (event: React.ChangeEvent<HTMLInputElement>) => setEnableRemember(event.target.checked);

  const validMinutes = useMemo(() => {
    if (!minutesEachScheduled) {
      return 'Campo obrigatório';
    }
    if (minutesEachScheduled > 0 && minutesEachScheduled <= 60) {
      return '';
    }
    return 'Digite um valor entre 1 e 60';
  }, [minutesEachScheduled]);

  const validDays = useMemo(() => {
    if (!daysToShow) {
      return 'Campo obrigatório';
    }
    if (daysToShow > 0 && daysToShow <= 10) {
      return '';
    }
    return 'Digite um valor entre 1 e 10';
  }, [daysToShow]);


  const onSave = async () => {
    const daysWork: DayWork[] = [];

    const checkAndAddDay = (day: string, dayWork: dayWorkEditing | undefined) => {
      if (dayWork?.enabled
        && dayWork.initialHour !== undefined
        && dayWork.initialMinute !== undefined
        && dayWork.finalHour !== undefined
        && dayWork.finalMinute !== undefined) {
        daysWork.push({ day, initialHour: dayWork.initialHour, initialMinute: dayWork.initialMinute, finalHour: dayWork.finalHour, finalMinute: dayWork.finalMinute });
      }
    }
    checkAndAddDay('0', daysWork0);
    checkAndAddDay('1', daysWork1);
    checkAndAddDay('2', daysWork2);
    checkAndAddDay('3', daysWork3);
    checkAndAddDay('4', daysWork4);
    checkAndAddDay('5', daysWork5);
    checkAndAddDay('6', daysWork6);

    await createUpdateConfig({
      minutesEachScheduled: Number(minutesEachScheduled),
      daysToShow: Number(daysToShow),
      chatbotEnabled: chatbotEnable,
      rememberEnabled: enableRemember,
      daysWork: daysWork
    })
    setFeedback({ title: 'Configurações salvas com sucesso' });
  }

  if (!ready) {
    <Container>
      <Typography variant='h3'>
        Carregando...
      </Typography>
    </Container>
  }

  return (
    <Container>
      <Typography variant='h3'>
        Configurações
      </Typography>
      <hr />
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          label="Minutos por serviços"
          value={minutesEachScheduled ?? ''}
          error={!!validMinutes}
          helperText={validMinutes}
          type='number'
          variant="standard"
          onChange={onChangeMinutes}
        />
        <TextField
          label="Dias de exibição"
          value={daysToShow ?? ''}
          error={!!validDays}
          helperText={validDays}
          type='number'
          variant="standard"
          onChange={onChangeDaysToShow}
        />
        <Paper style={{ padding: '5px', margin: '10px 0px 10px 0px' }}>
          <Typography variant='h5'>
            Mensagens
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={chatbotEnable} onChange={onChangeEnabledChatbot} />} label="Respostas automáticas" />
            </FormGroup>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={enableRemember} onChange={onChangeEnabledRemember} />} label="Lembretes" />
            </FormGroup>
          </Typography>
        </Paper>
        {
          daysWork1 && (
            <DayConfig
              title='Segunda'
              day={daysWork1}
              onChange={onChangeDay(setDaysWork1)}
            />
          )
        }
        {daysWork2 && (
          <DayConfig
            title='Terça'
            day={daysWork2}
            onChange={onChangeDay(setDaysWork2)}
          />
        )}
        {daysWork3 && (
          <DayConfig
            title='Quarta'
            day={daysWork3}
            onChange={onChangeDay(setDaysWork3)}
          />
        )}
        {daysWork4 && (
          <DayConfig
            title='Quinta'
            day={daysWork4}
            onChange={onChangeDay(setDaysWork4)}
          />
        )}
        {daysWork5 && (
          <DayConfig
            title='Sexta'
            day={daysWork5}
            onChange={onChangeDay(setDaysWork5)}
          />
        )}
        {daysWork6 && (
          <DayConfig
            title='Sábado'
            day={daysWork6}
            onChange={onChangeDay(setDaysWork6)}
          />
        )}
        {daysWork0 && (
          <DayConfig
            title='Domingo'
            day={daysWork0}
            onChange={onChangeDay(setDaysWork0)}
          />
        )}
      </Box>
      <Box>
        <Button variant="outlined" onClick={onSave} disabled={!minutesEachScheduled && !daysToShow}>Salvar</Button>
      </Box>
      <AlertFeedback alert={feedback} setClose={setFeedback} />
    </Container>
  );
};

export default ConfigComponent;
