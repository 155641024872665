import React, { useState, useEffect } from 'react';
import { streamWhats } from '../../services/firebase';
import { Box, Container, Typography } from '@mui/material';
import QRCode from "react-qr-code";
import moment from 'moment';
import { Timestamp } from 'firebase/firestore';

type QrcodeItem = {
  id: string,
  lastUpdate: Timestamp;
  qrcode?: string;
};

const Whats: React.FC = () => {
  const [lastUpdate, setLastUpdate] = useState<string>();
  const [qrCode, setQrCode] = useState<string>();

  useEffect(() => {
    const unsubscribe = streamWhats(
      (querySnapshot: any) => {
        const updatedWhatsConfig: QrcodeItem[] =
          querySnapshot.docs.map((docSnapshot: any) => ({ id: docSnapshot.id, ...docSnapshot.data() }));

        const qrCodeItem = updatedWhatsConfig.find(item => item.id === 'qrcode');

        if (qrCodeItem) {
          setQrCode(qrCodeItem.qrcode);
          setLastUpdate(moment(qrCodeItem.lastUpdate.seconds * 1000).format('DD/MM/yyyy HH:mm:ss'));
        }
      },
      (error: any) => {
        console.log('error', error)
        // setError('Erro buscando dados, favor recarregar a página.');
      }
    );
    return unsubscribe;
  }, []);

  return (
    <Container>
      <Typography variant='h3'>
        WhatsApp
      </Typography>
      <hr />
      <Box
        component="form"
        sx={{
          '& .MuiTextField-root': { m: 1, width: '25ch' },
        }}
        noValidate
        autoComplete="off"
      >
        {!!lastUpdate && (
          <Typography>
            Última atualização: {lastUpdate}
          </Typography>
        )}
        {!!qrCode && (
          <QRCode
            size={512}
            style={{ height: "auto", maxWidth: "512px", width: "100%" }}
            value={qrCode}
            viewBox={`0 0 512 512`}
          />
        )}
      </Box>
    </Container>
  );
};

export default Whats;
