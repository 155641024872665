import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Item, NewScheduleCalendar, Schedule, ScheduleItem } from '../../types';
import { EventAvailable, AddCircleOutline } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { TableRowStriped } from '../../Components';

type Props = {
  schedules: Schedule[];
  employees: Item[];
  onShowAddSchedule: (newScheduleParams: NewScheduleCalendar) => void;
  onShowScheduleDetails: (schedule: ScheduleItem) => void;
}

const ListScheduleds = ({
  schedules,
  employees,
  onShowAddSchedule,
  onShowScheduleDetails
}: Props) => {
  return (
    <TableContainer component={Paper} sx={{ maxHeight: window.innerHeight - 300 }}>
      <Table aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align='center'>Hora</TableCell>
            {
              employees.map(employee => (
                <TableCell key={employee.id} align='center'>{employee.name}</TableCell>
              ))
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {schedules.map((row) => (
            <TableRowStriped
              key={row.hour}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row" align='center'>
                {row.hour}
              </TableCell>
              {
                row.employees.map((schedule, index) => {
                  const onClick = () => {
                    if (schedule) {
                      onShowScheduleDetails(schedule);
                    } else {
                      onShowAddSchedule({
                        hour: row.hour,
                        employee: employees[index].id
                      });
                    }
                  }

                  return (
                    <TableCell key={`${row.hour}-${index}`} align='center'>
                      <IconButton onClick={onClick}>
                        {schedule ? (
                          <EventAvailable color='success' fontSize="large" />
                        ) : (
                          <AddCircleOutline color='disabled' />
                        )}
                      </IconButton>
                    </TableCell>

                  )
                })
              }
            </TableRowStriped>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ListScheduleds;
