import React from 'react';
import Auth from './Routes/Auth';
import Router from './Routes/Router';

const App = () => (
    <Auth>
        {(authenticated: boolean) => (
            <Router authenticated={authenticated} />
        )}
    </Auth>
);

export default App;
