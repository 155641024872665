import { DayWork, Item } from '../types';
import moment from 'moment';
import 'moment/locale/pt-br';

export const getBaseHours = (minutesEachScheduled: number, daysWork: DayWork[], day: string): Array<string> => {
    if (!minutesEachScheduled || !daysWork?.length || !day) {
        return [];
    }

    const dayMoment = moment(day, 'YYYY-MM-DD');

    if (!dayMoment.isValid()) {
        return [];
    }

    const dayWork = daysWork.find(dayWork => dayWork.day === dayMoment.format('d'));

    if (!dayWork) {
        return [];
    }

    const baseHours: Array<string> = [];

    const hour = moment(`${dayWork.initialHour.toString().padStart(2, '0')}:${dayWork.initialMinute.toString().padStart(2, '0')}`, 'HH:mm');
    while (hour.hour() < dayWork.finalHour || (hour.hour() === dayWork.finalHour && hour.minute() < dayWork.finalMinute)) {
        baseHours.push(hour.format('HH:mm'));
        hour.add(minutesEachScheduled, 'minutes');
    }

    return baseHours;
};

export const getNextDays = (daysToShow: number, daysWork: DayWork[]) => {
    if (!daysToShow || !daysWork?.length) {
        return [];
    }

    const nextDays: Item[] = [];
    const today = moment().utc().add(-3, 'hours');

    if (daysWork.some(dayWork => dayWork.day === today.format('d'))) {
        nextDays.push({ id: today.format('YYYY-MM-DD'), name: 'Hoje' });
    }

    for (let index = 1; index < daysToShow; index++) {
        const date = today.clone().add(index, 'days');
        if (daysWork.some(dayWork => dayWork.day === date.format('d'))) {
            nextDays.push({ id: date.format('YYYY-MM-DD'), name: date.format('dddd') });
        }
    }

    return nextDays;
};
