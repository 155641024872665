import React, { useState } from 'react';
import {
    Button,
    Container,
    CssBaseline,
    TextField,
    Typography,
} from '@mui/material';
import { loginPhone } from '../../services/firebase';
import { IMaskInput } from 'react-imask';
import { ConfirmationResult } from 'firebase/auth';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="(00) 00000-0000"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

const LoginPhone: React.FC = () => {
    const [phone, setPhone] = useState('');
    const [codeConfirmation, setCodeConfirmation] = useState('');
    const [confirmationResult, setConfirmationResult] = useState<ConfirmationResult | undefined>(undefined);

    const phoneRegex = /^\(\d{2}\) 9\d{4}-\d{4}$/;

    const error = !!phone && !phoneRegex.test(phone);

    const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value);

    const onChangeCodeConfirmation = (e: React.ChangeEvent<HTMLInputElement>) => setCodeConfirmation(e.target.value);

    const handleLogin = async () => {
        if (!error) {
            try {
                const loginResult = await loginPhone(`+55${phone.replace(/\D/g, '')}`);

                console.log('loginResult', loginResult);

                setConfirmationResult(loginResult);
            } catch (error) {
                alert(error);
            }
        }
    };

    const handleConfirmeCode = async () => {
        if (!error) {
            try {
                await confirmationResult?.confirm(codeConfirmation);
            } catch (error) {
                alert(error);
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div>
                <Typography variant="h5">Barbearia Seu Jota</Typography>
                <Typography variant="body1">Para realizar o agendamento, informe seu número de celular</Typography>
                <form>
                    <TextField
                        error={error}
                        id="phone"
                        label="Celular"
                        variant="outlined"
                        value={phone}
                        onChange={onChangePhone}
                        InputProps={{
                            inputComponent: TextMaskCustom as any,
                        }}
                        helperText={error ? 'Please enter a valid phone number as (99) 99999-9999' : ''}
                        style={{ margin: '10px' }}
                        disabled={!!confirmationResult}
                    />

                    {
                        confirmationResult ? (
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="primary"
                                onClick={handleLogin}
                                disabled={error}
                            >
                                Login
                            </Button>
                        ) : (
                            <div>
                                <TextField
                                    id="codeConfirmation"
                                    label="Código de confirmação"
                                    variant="outlined"
                                    value={codeConfirmation}
                                    onChange={onChangeCodeConfirmation}
                                    style={{ margin: '10px' }}
                                />
                                <Button
                                    type="button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleConfirmeCode}
                                    disabled={codeConfirmation.length < 3}
                                >
                                    Confirmar
                                </Button>
                            </div>
                        )
                    }

                </form>
            </div>
            <div id="recaptcha-container"></div>
        </Container>
    );
};

export default LoginPhone;
