import React, { useState } from 'react';
import {
    Button,
    Container,
    CssBaseline,
    TextField,
    Typography,
} from '@mui/material';
import { login } from '../../services/firebase';

const LoginMail: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    const showErrorEmail = !!email && !isEmailValid;

    const isPasswordValid = password.length >= 6;
    const showErrorPassword = !!password && !isPasswordValid;

    const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value);
    const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value);

    const handleLogin = async () => {
        if (isEmailValid && isPasswordValid) {
            try {
                await login(email, password);
            } catch (error) {
                alert(error);
            }
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div>
                <Typography variant="h5">Login</Typography>
                <form>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="E-mail"
                        type="email"
                        value={email}
                        onChange={onChangeEmail}
                        error={showErrorEmail}
                        helperText={showErrorEmail ? 'Email inválido' : ''}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        label="Senha"
                        type="password"
                        value={password}
                        onChange={onChangePassword}
                        error={showErrorPassword}
                        helperText={showErrorPassword ? 'Mínimo de 6 caracteres' : ''}
                    />
                    <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleLogin}
                        disabled={!isEmailValid || !isPasswordValid}
                    >
                        Login
                    </Button>
                </form>
            </div>
        </Container>
    );
};

export default LoginMail;
