import { Outlet, Routes, Route, Link } from "react-router-dom";
import Schedule from '../Schedule';
import Employee from '../Employee';
import Service from '../Service';
import Config from '../Config';
import Whats from '../Whats';
import ContactsIgnore from '../ContactsIgnore';
import Menu from './Menu';
import LoginMail from "../LoginMail";
import LoginPhone from "../LoginPhone";

type Props = {
    authenticated: boolean;
}

export default function Router({ authenticated }: Props) {
    if (!authenticated) {
        return (
            <Routes>
                <Route path="/" element={<Outlet />}>
                    <Route index element={<LoginMail />} />
                    <Route path="/loginphone" element={<LoginPhone />} />
                </Route>
            </Routes>
        );
    }

    return (
        <div>
            <Routes>
                <Route path="/" element={<Menu />}>
                    <Route index element={<Schedule />} />
                    <Route path="/employee" element={<Employee />} />
                    <Route path="/service" element={<Service />} />
                    <Route path="/config" element={<Config />} />
                    <Route path="/whats" element={<Whats />} />
                    <Route path="/contacts" element={<ContactsIgnore />} />
                    <Route path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </div>
    );
}

function NoMatch() {
    return (
        <div>
            <h2>Nothing to see here!</h2>
            <p>
                <Link to="/">Go to the home page</Link>
            </p>
        </div>
    );
}
