import React, { useState, useEffect, useCallback } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Paper, TextField, Typography } from '@mui/material';

export type Props = {
  day: Day;
  onChange: (dayConfig: Day) => void;
  title: string;
};

export type Day = {
  enabled: boolean,
  initialHour?: number,
  initialMinute?: number,
  finalHour?: number;
  finalMinute?: number;
};

const DayConfig: React.FC<Props> = ({ day, onChange, title }) => {
  const [localDay, setLocalDay] = useState<Day>(day);

  const validationErrorHour = useCallback((hour?: number, initial?: number, final?: number) => {
    if (!hour) {
      return 'Campo obrigatório';
    }
    const hourNumber = Number(hour);
    if (hourNumber > 0 && hourNumber < 24) {
      if (initial && hourNumber <= initial) {
        return 'Deve ser maior que a hora inicial';
      }
      if (final && hourNumber >= final) {
        return 'Deve ser menor que a hora final';
      }
      return '';
    }
    return 'Digite um valor entre 1 e 23';
  }, []);

  const validationErrorMinute = useCallback((minute?: number) => {
    if (minute !== 0 && !minute) {
      return 'Campo obrigatório';
    }
    const minuteNumber = Number(minute);
    if (minuteNumber >= 0 && minuteNumber <= 59) {
      return '';
    }
    return 'Digite um valor entre 0 e 59';
  }, []);

  const initialHourError = localDay.enabled ? validationErrorHour(localDay.initialHour, undefined, localDay.finalHour) : '';
  const initialMinuteError = localDay.enabled ? validationErrorMinute(localDay.initialMinute) : '';
  const finalHourError = localDay.enabled ? validationErrorHour(localDay.finalHour, localDay.initialHour) : '';
  const finalMinuteError = localDay.enabled ? validationErrorMinute(localDay.finalMinute) : '';

  useEffect(() => {
    if (!localDay.enabled) {
      onChange({ enabled: false });
    } else {
      if (!validationErrorHour(localDay.initialHour, undefined, localDay.finalHour)
        && !validationErrorHour(localDay.finalHour, localDay.initialHour)
        && !validationErrorMinute(localDay.initialMinute)
        && !validationErrorMinute(localDay.finalMinute)
      ) {
        onChange(localDay);
      }
    }
  }, [localDay]);

  const onChangeEnabled = (event: React.ChangeEvent<HTMLInputElement>) => setLocalDay(prevState => ({ ...prevState, enabled: event.target.checked }));
  const onChangeInitialHour = (event: React.ChangeEvent<HTMLInputElement>) => setLocalDay(prevState => ({ ...prevState, initialHour: event.target.value ? Number(event.target.value) : undefined }));
  const onChangeInitialMinute = (event: React.ChangeEvent<HTMLInputElement>) => setLocalDay(prevState => ({ ...prevState, initialMinute: event.target.value ? Number(event.target.value) : undefined }));
  const onChangeFinalHour = (event: React.ChangeEvent<HTMLInputElement>) => setLocalDay(prevState => ({ ...prevState, finalHour: event.target.value ? Number(event.target.value) : undefined }));
  const onChangeFinalMinute = (event: React.ChangeEvent<HTMLInputElement>) => setLocalDay(prevState => ({ ...prevState, finalMinute: event.target.value ? Number(event.target.value) : undefined }));

  return (
    <Paper style={{ padding: '5px', margin: '10px 0px 10px 0px' }}>
      <Typography variant='h5'>
        {title}
        <FormGroup>
          <FormControlLabel control={<Checkbox checked={localDay.enabled} onChange={onChangeEnabled} />} label="Ativo" />
        </FormGroup>
        <TextField
          label="Hora inicial"
          value={localDay.initialHour ?? ''}
          error={!!initialHourError}
          helperText={initialHourError}
          disabled={!localDay.enabled}
          type='number'
          variant="standard"
          onChange={onChangeInitialHour}
        />
        <TextField
          label="Minuto inicial"
          value={localDay.initialMinute ?? ''}
          error={!!initialMinuteError}
          helperText={initialMinuteError}
          disabled={!localDay.enabled}
          type='number'
          variant="standard"
          onChange={onChangeInitialMinute}
        />
        <TextField
          label="Hora final"
          value={localDay.finalHour ?? ''}
          error={!!finalHourError}
          helperText={finalHourError}
          disabled={!localDay.enabled}
          type='number'
          variant="standard"
          onChange={onChangeFinalHour}
        />
        <TextField
          label="Minuto final"
          value={localDay.finalMinute ?? ''}
          error={!!finalMinuteError}
          helperText={finalMinuteError}
          disabled={!localDay.enabled}
          type='number'
          variant="standard"
          onChange={onChangeFinalMinute}
        />
      </Typography>
    </Paper>
  );
};

export default DayConfig;
