import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Contact } from '../../types';
import { TableRowStriped } from '../../Components';
import { Button } from '@mui/material';

type Props = {
  contacts: Contact[];
  onDeleteContact: (phone: string) => void;
}

const ListContacts = ({
  contacts,
  onDeleteContact
}: Props) => (
  <TableContainer component={Paper} style={{ marginBottom: '10px' }}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align='center'>Nome</TableCell>
          <TableCell align='center'></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {contacts.map((row) => (
          <TableRowStriped
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell component="td" scope="row" align='center'>
              {row.phone}
            </TableCell>
            <TableCell component="td" scope="row" align='center'>
              <Button variant="outlined" onClick={() => onDeleteContact(row.phone)}>Apagar</Button>
            </TableCell>
          </TableRowStriped>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default ListContacts;
