import React, { useState, useEffect } from 'react';
import { streamListEmployee } from '../../services/firebase';
import List from './List';
import { Button, Container, Typography } from '@mui/material';
import { AlertFeedbackData, Employee, NewEmployee } from '../../types';
import ModalRegister from './ModalRegister';
import { AlertFeedback } from '../../Components';

const Crud: React.FC = () => {
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [newEmployee, setNewEmployee] = useState<NewEmployee | {} | undefined>(undefined);
  const [error, setError] = useState<string>();
  const [feedback, setFeedback] = useState<AlertFeedbackData>();

  useEffect(() => {
    const unsubscribe = streamListEmployee(
      (querySnapshot: any) => {
        const updatedItems =
          querySnapshot.docs.map((snapshot: any) => ({ id: snapshot.id, ...snapshot.data() }));
        setEmployees(updatedItems);
      },
      (error: any) => {
        console.log('error', error);
        setError('Erro buscando dados, favor recarregar a página.');
      }
    );
    return unsubscribe;
  }, [setEmployees]);

  const onEditEmployee = (newEmployeeParam?: NewEmployee) => setNewEmployee(newEmployeeParam);
  const onAddEmployee = () => setNewEmployee({});

  const onHideAddSchedule = () => setNewEmployee(undefined);

  return (
    <Container>
      <Typography variant='h3'>
        Funcionários
      </Typography>
      {error && <Typography variant='h5'>{error}</Typography>}
      <br />
      <List employees={employees} onShowDetails={onEditEmployee} />
      <Button variant="outlined" onClick={onAddEmployee}>Adicionar</Button>
      {
        !!newEmployee && (
          <ModalRegister
            onClose={onHideAddSchedule}
            employee={newEmployee}
            setFeedback={setFeedback}
          />
        )
      }
      <AlertFeedback alert={feedback} setClose={setFeedback} />
    </Container>
  );
};

export default Crud;
