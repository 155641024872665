import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Service } from '../../types';
import { TableRowStriped } from '../../Components';

type Props = {
  services: Service[];
  onShowDetails: (service: Service) => void;
}

const ListServices = ({
  services,
  onShowDetails
}: Props) => (
  <TableContainer component={Paper} style={{ marginBottom: '10px' }}>
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell align='center'>Nome</TableCell>
          <TableCell align='center'>Duração</TableCell>
          <TableCell align='center'>Ordem</TableCell>
          <TableCell align='center'>Ativo</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {services.map((row) => (
          <TableRowStriped
            key={row.id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            onClick={() => onShowDetails(row)}
          >
            <TableCell component="th" scope="row" align='center'>
              {row.name}
            </TableCell>
            <TableCell component="th" scope="row" align='center'>
              {row.duration}
            </TableCell>
            <TableCell component="th" scope="row" align='center'>
              {row.order}
            </TableCell>
            <TableCell component="th" scope="row" align='center'>
              {row.active ? 'Sim' : 'Não'}
            </TableCell>
          </TableRowStriped>
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

export default ListServices;
