import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import { cancelSchedule, getContext, getClient } from '../../services/firebase';
import { ScheduleItem, Client, Service, AlertFeedbackData } from '../../types';

type Props = {
    services: Service[];
    schedule: ScheduleItem;
    onClose: () => void;
    setFeedback: (alert?: AlertFeedbackData) => void;
}

const ModalScheduleDetails = ({
    services,
    schedule,
    onClose,
    setFeedback,
}: Props) => {
    const [serviceName, setServiceName] = useState<string>();
    const [client, setClient] = useState<Client>();

    useEffect(() => {
        const getData = async () => {
            const context = await getContext(schedule.context);

            if (context) {
                const service = services.find(service => service.id === context.service);

                if (service?.name) {
                    setServiceName(service.name);
                }

                if (context.user) {
                    const client = await getClient(context.user);

                    if (client) {
                        setClient(client);
                    }
                }
            }
        }
        getData();
    });

    const onCancelSchedule = async () => {
        try {
            await cancelSchedule(schedule.context);
            setFeedback({ title: 'Agendamento cancelado com sucesso!' });
            onClose();
        } catch (error) {
            setFeedback({ title: 'Erro ao cancelar o agendamento, verifique se o horário continua agendado e tente novamente.', type: 'error' });
        }
    }

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>Reservar horário</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Detalhes do Agendamento
                </DialogContentText>
                <br />
                <Typography>
                    Parceiro: <b>{schedule.employee}</b>
                </Typography>
                <Typography>
                    Data: <b>{schedule.day}</b>
                </Typography>
                <Typography>
                    Hora: <b>{schedule.hour}</b>
                </Typography>
                {
                    !!serviceName && (
                        <Typography>
                            Serviço: <b>{serviceName}</b>
                        </Typography>
                    )
                }
                {
                    !!client && (
                        <>
                            <Typography>
                                Cliente: <b>{client.name}</b>
                            </Typography>
                            <Typography>
                                Telefone: <b>{client.phone}</b>
                            </Typography>
                        </>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Fechar</Button>
                <Button variant="outlined" onClick={onCancelSchedule}>Cancelar Agendamento</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ModalScheduleDetails;
