import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import { createUpdateService } from '../../services/firebase';
import { AlertFeedbackData, NewService } from '../../types';

type Props = {
    service: NewService;
    onClose: () => void;
    setFeedback: (alert?: AlertFeedbackData) => void;
}

const ModalRegister = ({
    service,
    onClose,
    setFeedback,
}: Props) => {
    const [serviceNew, setServiceNew] = useState<NewService>({ ...service, active: service.id ? service.active : true });
    const [saving, setSaving] = useState<boolean>(false);

    const onSave = async () => {
        setSaving(true);
        try {
            await createUpdateService(serviceNew, service?.id);
            setFeedback({ title: 'Dados salvos com sucesso!' })
            onClose();
        } catch (error) {
            setFeedback({ title: 'Erro ao salvar, favor tentar novamente.', type: 'error' })
        } finally {
            setSaving(false);
        }
    }

    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => setServiceNew(prevState => ({ ...prevState, name: event.target.value }));
    const onChangeOrder = (event: React.ChangeEvent<HTMLInputElement>) => setServiceNew(prevState => ({ ...prevState, order: Number(event.target.value) }));
    const onChangeDuration = (event: React.ChangeEvent<HTMLInputElement>) => setServiceNew(prevState => ({ ...prevState, duration: Number(event.target.value) }));
    const onChangeActive = (event: React.ChangeEvent<HTMLInputElement>) => setServiceNew(prevState => ({ ...prevState, active: event.target.checked }));

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>Reservar horário</DialogTitle>
            <DialogContent>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        label="Nome"
                        defaultValue={serviceNew.name}
                        error={!serviceNew.name}
                        helperText={!serviceNew.name ? "Campo obrigatório" : ""}
                        variant="filled"
                        onChange={onChangeName}
                    />
                    <TextField
                        label="Ordem"
                        defaultValue={serviceNew.order}
                        error={!serviceNew.order}
                        helperText={!serviceNew.order ? "Campo obrigatório" : ""}
                        variant="filled"
                        onChange={onChangeOrder}
                    />
                    <TextField
                        label="Duração"
                        defaultValue={serviceNew.duration}
                        error={!serviceNew.duration}
                        helperText={!serviceNew.duration ? "Campo obrigatório" : ""}
                        variant="filled"
                        onChange={onChangeDuration}
                    />
                    <FormGroup>
                        <FormControlLabel control={<Checkbox checked={!!serviceNew.active} onChange={onChangeActive} />} label="Ativo" />
                    </FormGroup>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={saving}>Fechar</Button>
                {
                    service?.id ? (
                        <Button onClick={onSave} disabled={saving}>Salvar</Button>
                    ) : (
                        <Button onClick={onSave} disabled={saving}>Adicionar</Button>
                    )
                }
            </DialogActions>
        </Dialog>
    );
}

export default ModalRegister;
