import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { AlertFeedbackData, Item, Service } from '../../types';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { FormHelperText, TextField } from '@mui/material';
import { addSchedule } from '../../services/firebase';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import { IMaskInput } from 'react-imask';

type Props = {
    minutesEachScheduled: number;
    service: string;
    listServices: Service[];
    employeeId: string;
    listEmployees: Item[];
    day: string;
    listDays: Item[];
    hour: string;
    listHours: string[];
    onClose: () => void;
    setFeedback: (alert?: AlertFeedbackData) => void;
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="(00) 00000-0000"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

const AddSchedule = ({
    minutesEachScheduled,
    service,
    listServices,
    employeeId,
    listEmployees,
    day,
    listDays,
    hour,
    listHours,
    onClose,
    setFeedback,
}: Props) => {
    const getServiceDuration = (serviceId: string) => (listServices.find(item => item.id === serviceId)?.duration || 1) * minutesEachScheduled;

    const [selectService, setSelectService] = useState<string>(service);
    const [selectServiceDuration, setSelectServiceDuration] = useState<number>(getServiceDuration(service));
    const [selectEmployee, setSelectEmployee] = useState<string>(employeeId);
    const [selectDay, setSelectDay] = useState<string>(day);
    const [selectHour, setSelectHour] = useState<string>(hour);
    const [name, setName] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const errorPhone = !!phone && !/^\(\d{2}\) 9\d{4}-\d{4}$/.test(phone);

    const onChangeService = (event: SelectChangeEvent) => {
        const serviceId = event.target.value as string;
        setSelectService(serviceId);
        setSelectServiceDuration(getServiceDuration(serviceId));
    }
    const onChangeEmployee = (event: SelectChangeEvent) => setSelectEmployee(event.target.value as string);
    const onChangeDay = (newValue: moment.Moment | null) => setSelectDay(newValue ? newValue.format('YYYY-MM-DD') : '');
    const onChangeHour = (event: SelectChangeEvent) => setSelectHour(event.target.value as string);
    const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value as string);
    const onChangePhone = (event: React.ChangeEvent<HTMLInputElement>) => setPhone(event.target.value);

    const onClickSchedule = async () => {
        const schedule = { service: selectService, employee: selectEmployee, day: selectDay, hour: selectHour, name, phone: `55${phone.replace(/\D/g, '')}` };
        try {
            await addSchedule(schedule);
            setFeedback({ title: 'Agendamento realizado com sucesso!' });
            onClose();
        } catch (error) {
            setFeedback({ title: 'Erro ao salvar agendamento, verifique se o horário continua disponível para o tempo do serviço e tente novamente.', type: 'error' })
        }
    }
    const dayMoment = selectDay ? moment(selectDay, 'YYYY-MM-DD') : null;
    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>Reservar horário</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Selecione os dados do agendamento e confirme.
                </DialogContentText>
                <br />
                <FormControl fullWidth>
                    <InputLabel>Serviço</InputLabel>
                    <Select
                        value={selectService}
                        label="Serviço"
                        onChange={onChangeService}
                    >
                        {
                            listServices.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                        }
                    </Select>
                    <FormHelperText>Tempo de serviço: {selectServiceDuration} minutos</FormHelperText>
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                    <InputLabel>Empregado</InputLabel>
                    <Select
                        value={selectEmployee}
                        label="Empregado"
                        onChange={onChangeEmployee}
                    >
                        {
                            listEmployees.map(item => <MenuItem key={item.id} value={item.id}>{item.name}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker value={dayMoment} onChange={onChangeDay} label="Dia" />
                    </LocalizationProvider>
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                    <InputLabel>Hora</InputLabel>
                    <Select
                        value={selectHour}
                        label="Hora"
                        onChange={onChangeHour}
                    >
                        {
                            listHours.map(item => <MenuItem key={item} value={item}>{item}</MenuItem>)
                        }
                    </Select>
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                    <TextField
                        label="Nome"
                        variant="outlined"
                        value={name}
                        onChange={onChangeName}
                    />
                </FormControl>
                <br />
                <br />
                <FormControl fullWidth>
                    <TextField
                        error={errorPhone}
                        id="phone"
                        label="Celular"
                        variant="outlined"
                        value={phone}
                        onChange={onChangePhone}
                        InputProps={{
                            inputComponent: TextMaskCustom as any,
                        }}
                        helperText={errorPhone ? 'Please enter a valid phone number as (99) 99999-9999' : ''}
                    />
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" onClick={onClose}>Cancelar</Button>
                <Button variant="outlined" onClick={onClickSchedule} disabled={errorPhone}>Confirmar</Button>
            </DialogActions>
        </Dialog>
    );
}

export default AddSchedule;
