import React, { useState, useEffect } from 'react';
import { streamListService } from '../../services/firebase';
import List from './List';
import { Button, Container, Typography } from '@mui/material';
import { Service, NewService, AlertFeedbackData } from '../../types';
import ModalRegister from './ModalRegister';
import { AlertFeedback } from '../../Components';

const Crud: React.FC = () => {
  const [services, setServices] = useState<Service[]>([]);
  const [newService, setNewService] = useState<NewService | {} | undefined>(undefined);
  const [error, setError] = useState<string>();
  const [feedback, setFeedback] = useState<AlertFeedbackData>();

  useEffect(() => {
    const unsubscribe = streamListService(
      (querySnapshot: any) => {
        const updatedItems =
          querySnapshot.docs.map((snapshot: any) => ({ id: snapshot.id, ...snapshot.data() }));
        setServices(updatedItems);
      },
      (error: any) => {
        console.log('error', error);
        setError('Erro buscando dados, favor recarregar a página.');
      }
    );
    return unsubscribe;
  }, [setServices]);

  const onEditService = (newServiceParam?: NewService) => setNewService(newServiceParam);
  const onAddService = () => setNewService({});

  const onHideAddSchedule = () => setNewService(undefined);

  return (
    <Container>
      <Typography variant='h3'>
        Funcionários
      </Typography>
      {error && <Typography variant='h5'>{error}</Typography>}
      <br />
      <List services={services} onShowDetails={onEditService} />
      <Button variant="outlined" onClick={onAddService}>Adicionar</Button>
      {
        !!newService && (
          <ModalRegister
            onClose={onHideAddSchedule}
            service={newService}
            setFeedback={setFeedback}
          />
        )
      }
      <AlertFeedback alert={feedback} setClose={setFeedback} />
    </Container>
  );
};

export default Crud;
