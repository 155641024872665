import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, TextField } from '@mui/material';
import { createContact } from '../../services/firebase';
import { AlertFeedbackData } from '../../types';
import { IMaskInput } from 'react-imask';

type Props = {
    onClose: () => void;
    setFeedback: (alert?: AlertFeedbackData) => void;
}

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="(00) 00000-0000"
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

const ModalRegister = ({
    onClose,
    setFeedback,
}: Props) => {
    const [phone, setPhone] = useState('');
    const [saving, setSaving] = useState<boolean>(false);

    const onChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => setPhone(e.target.value);

    const phoneRegex = /^\(\d{2}\) 9\d{4}-\d{4}$/;

    const error = !!phone && !phoneRegex.test(phone);

    const onSave = async () => {
        setSaving(true);
        try {
            await createContact(`55${phone.replace(/\D/g, '')}`);
            setFeedback({ title: 'Dados salvos com sucesso!' })
            onClose();
        } catch (error) {
            setFeedback({ title: 'Erro ao salvar, favor tentar novamente.', type: 'error' })
        } finally {
            setSaving(false);
        }
    }

    return (
        <Dialog open onClose={onClose}>
            <DialogTitle>Cadastro de contato</DialogTitle>
            <DialogContent>
                <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField
                        error={error}
                        id="phone"
                        label="Celular"
                        variant="outlined"
                        value={phone}
                        onChange={onChangePhone}
                        InputProps={{
                            inputComponent: TextMaskCustom as any,
                        }}
                        helperText={error ? 'Please enter a valid phone number as (99) 99999-9999' : ''}
                        style={{ margin: '10px' }}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} disabled={saving}>Fechar</Button>
                <Button onClick={onSave} disabled={saving}>Adicionar</Button>
            </DialogActions>
        </Dialog>
    );
}

export default ModalRegister;
