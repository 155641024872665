import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { AlertFeedbackData } from '../types';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

type Props = {
    alert?: AlertFeedbackData,
    setClose: (alert?: AlertFeedbackData) => void,
}

export default function AlertFeedback({ alert, setClose }: Props) {
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setClose(undefined);
    };

    if (!alert) {
        return null;
    }

    return (
        <Snackbar open autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity={alert.type || 'success'} sx={{ width: '100%' }}>
                {alert.title}
            </Alert>
        </Snackbar>
    );
}
