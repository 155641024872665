import React, { useState, useEffect, useMemo } from 'react';
import { listEmployees, listServices, getConfig } from '../../services/firebase';
import { Container, Typography } from '@mui/material';
import { getNextDays } from '../../utils/utils';
import { Item, Service, Config } from '../../types';
import ScheduleComponent from './ScheduleList';

const BaseData: React.FC = () => {
  const [config, setConfig] = useState<Config>();
  const [employees, setEmployees] = useState<Item[]>([]);
  const [services, setServices] = useState<Service[]>([]);

  const baseDays = useMemo(() => getNextDays(config?.daysToShow || 0, config?.daysWork || []), [config]);

  useEffect(() => {
    const getData = async () => {
      setConfig(await getConfig());
      setEmployees(await listEmployees(true));
      setServices(await listServices(true));
    }
    getData();
  }, []);

  if (!config || !baseDays?.length || !employees || !services) {
    return (
      <Container>
        <Typography variant='h3' >
          Carregando...
        </Typography >
      </Container>
    )
  }

  return (
    <Container>
      <Typography variant='h3'>
        Agendamentos
      </Typography>
      <ScheduleComponent
        config={config}
        employees={employees}
        services={services}
        baseDays={baseDays}
      />
    </Container>
  );
};

export default BaseData;