import { Outlet, useNavigate } from "react-router-dom";
import IconCalendarMonth from '@mui/icons-material/CalendarMonth';
import IconPerson from '@mui/icons-material/Person';
import IconContentCut from '@mui/icons-material/ContentCut';
import IconSettings from '@mui/icons-material/Settings';
import IconMenu from '@mui/icons-material/Menu';
import Phone from '@mui/icons-material/Phone';
import WhatsApp from '@mui/icons-material/WhatsApp';
import { Button, MenuItem, IconButton, Typography } from "@mui/material";
import { logout } from '../../services/firebase';
import styled from "styled-components";
import Menu, { MenuProps } from '@mui/material/Menu';
import React from "react";

export default function MenuComponent() {
    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const onNavigate = (newLocation: string) => {
        navigate(newLocation);
        handleClose();
    }

    const handleLogout = async () => logout();

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <div>
                    <IconButton
                        onClick={handleClick}
                    >
                        <IconMenu />
                    </IconButton>
                    <Menu
                        id="demo-customized-menu"
                        MenuListProps={{
                            'aria-labelledby': 'demo-customized-button',
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={() => onNavigate('/')} disableRipple>
                            <IconCalendarMonth />
                            Agendamento
                        </MenuItem>
                        <MenuItem onClick={() => onNavigate('/employee')} disableRipple>
                            <IconPerson />
                            Funcionários
                        </MenuItem>
                        <MenuItem onClick={() => onNavigate('/service')} disableRipple>
                            <IconContentCut />
                            Serviços
                        </MenuItem>
                        <MenuItem onClick={() => onNavigate('/contacts')} disableRipple>
                            <Phone />
                            Contatos
                        </MenuItem>
                        <MenuItem onClick={() => onNavigate('/config')} disableRipple>
                            <IconSettings />
                            Configuração
                        </MenuItem>
                        <MenuItem onClick={() => onNavigate('/whats')} disableRipple>
                            <WhatsApp />
                            WhatsApp
                        </MenuItem>
                    </Menu>
                </div>
                <Typography variant='h5' style={{ marginTop: '5px' }}>
                    HorarioZAP
                </Typography>
                <Button onClick={handleLogout} >
                    Sair
                </Button>
            </div>
            <hr />
            <Outlet />
        </div>
    );
}

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
));
